import React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { useSelector } from "react-redux";
import { Typography } from "@mui/joy";
import { ticketState } from "../../reducers/ticketSlice";

export default function TicketTable() {
  const { ticketData } = useSelector(ticketState);
  console.log(ticketData);
  return (
    <div>
      {ticketData ? (
        <Sheet
          sx={{
            height: { xs: 400, md: 400 },
            width: "100%",
            overflow: "auto",
          }}
        >
          {/* <Box sx={{ overflowX: { xs: "auto", md: "none" } }}> */}
          <Table
            aria-label="table with sticky header"
            stickyHeader
            stickyFooter
            stripe="odd"
            hoverRow
            style={{ minWidth: 700 }}
          >
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>PNR</th>
                <th>Date </th>
                <th>ARRIVAL </th>
                <th>DEPARTURE</th>
                <th>Flight </th>
                <th>SECTOR START CODE </th>
                <th>SECTOR END CODE</th>
                <th>Sector start </th>
                <th>Sector end </th>
              </tr>
            </thead>
            <tbody>
              {ticketData.map((row, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{row["PNR"]}</td>
                  <td>{row["Date"]}</td>
                  <td style={{ wordWrap: "break-word" }}>{row["ARRIVAL"]}</td>
                  <td>{row["DEPARTURE"]}</td>
                  <td>{row["Flight"]}</td>
                  <td>{row["SECTOR START CODE"]}</td>
                  <td>{row["SECTOR END CODE"]}</td>
                  <td>{row["Sector start"]}</td>
                  <td>{row["Sector end"]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      ) : (
        <Typography level="h6">Please upload a file</Typography>
      )}
    </div>
  );
}

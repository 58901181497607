import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Root from "./Routes/root";

export default function JoyOrderDashboardTemplate() {
  return (
    <>
      <Routes>
        <Route>
          <Route path="*" element={<Root />} />
        </Route>
      </Routes>
    </>
  );
}

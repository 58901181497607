import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Select,
  Option,
  Typography,
} from "@mui/joy";
import React from "react";

export default function App() {
  return (
    <Container>
      <Typography level="h5" mb={3}>
        FeedBack form
      </Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1 }} component="form">
        <Grid xs={12} md={6}>
          <FormControl id="Id" required size="sm" color="primary">
            <FormLabel>Select Status</FormLabel>
            <Select defaultValue="dog">
              <Option value="dog">Enable</Option>
              <Option value="cat">Disable</Option>
            </Select>
            {/* <FormHelperText>Help!</FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
            gap: 2,
          }}
        >
          <Button size="sm" sx={{ px: 2 }}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

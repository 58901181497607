import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getMessaging } from "firebase/messaging";

export const App = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APPID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

export const auth = getAuth(App);
export const db_firestore = getFirestore(App);
export const storage = getStorage(App);
export const db_database = getDatabase(App);
export const functions = getFunctions(App, "asia-south1");
export const message = getMessaging(App);
// if (!window.location.hostname === "localhost") {
//     connectAuthEmulator(auth, "http://localhost:9099");
//     connectFirestoreEmulator(db_firestore, "localhost", 8080);
//     connectDatabaseEmulator(db_database, "localhost", 9000);
//     connectStorageEmulator(storage, "localhost", 9199);
//     connectFunctionsEmulator(functions, "localhost",5001);
// }

import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import { Avatar, Box } from "@mui/joy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { liveFeedState, setCurrentIndex } from "../../reducers/liveFeedSlice";

export default function LiveFeedCard({item}) {
  const { currentIndex } = useSelector(liveFeedState);
  const dispatch = useDispatch();
  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <AccountCircleIcon />
        <Typography>{item.userName}</Typography>
      </Box>
      <CardOverflow>
        <AspectRatio
          ratio="1.5"
          sx={{
            borderRadius: 0,
          }}
        >
          <img
            src={item.url.Location}
            // srcSet={`${item.url.Location}auto=format&fit=crop&w=318&dpr=2 2x`}
            loading="lazy"
            alt="..."
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            my: 1,
            gap: 1,
          }}
        >
          <Typography level="h2" fontSize="md" className="me-auto">
          {item.title}
          </Typography>
          <Typography level="body2" fontSize="sm">
            {item.likeCount}
          </Typography>
          <FavoriteIcon sx={{ cursor: "pointer" }} />
        </Box>
      

        <Typography level="body3" sx={{ mt: 0.5 }}>
       {item.description}
        </Typography>
        <Typography level="body3" sx={{ mt: 0.5 }}>
       {item.hash}
        </Typography>
      </CardContent>
    </Card>
  );
}

import React from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Person2Icon from "@mui/icons-material/Person2";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useDispatch, useSelector } from "react-redux";
import { dashboardState, setDashboardTab } from "../../reducers/tabSlice";
import { toggleSidebar } from "../../utils/utils";

const data = [
  {
    name: "CSV Upload",
    icon: <Person2Icon />,
  },
  {
    name: "Push Notification",
    icon: <NotificationsActiveIcon />,
  },
  {
    name: "FeedBack Form",
    icon: <PollOutlinedIcon />,
  },
  {
    name: "Live Feed",
    icon: <DynamicFeedIcon />,
  },
  {
    name: "Ticket Details",
    icon: <AirplaneTicketIcon />,
  },
  {
    name: "SOS",
    icon: <ReportProblemIcon />,
  },
];

export default function ListItems() {
  const dispatch = useDispatch();
  const { dashboardTab } = useSelector(dashboardState);
  return (
    <>
      <List
        sx={{
          "--ListItem-radius": "8px",
          "--List-gap": "4px",
          "--List-nestedInsetStart": "40px",
        }}
      >
        {data?.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => {
              dispatch(setDashboardTab({ tab: item.name }));
              toggleSidebar();
            }}
          >
            <ListItemButton>
              <ListItemDecorator
                sx={{
                  color: dashboardTab === item.name ? "#007FFF" : "inherit",
                }}
              >
                {item.icon}
              </ListItemDecorator>
              <ListItemContent
                sx={{
                  color: dashboardTab === item.name ? "#007FFF" : "inherit",
                }}
              >
                {item.name}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db_firestore } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";
const initialState = {
  currentTab: "Upload CSV",
  userData: "",
};

export const registerUserAsync = createAsyncThunk(
  "user/registerUser",
  async ({ parsedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      const responses = [];

      for (const user of parsedData) {
        try {
          const response = await createUserWithEmailAndPassword(
            auth,
            user.Email,
            "password"
          );
          const response2 = await setDoc(
            doc(db_firestore, "users", response.user.uid),
            user
          );
          responses.push(response.user);
        } catch (error) {
          if (error.code === "auth/email-already-in-use") {
            console.log(`Skipped duplicate email: ${user.Email}`);
          } else {
            throw error; // Re-throw other errors for proper rejection
          }
        }
      }

      return responses;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload.value;
    },
    setUserData: (state, action) => {
      state.userData = action.payload.value;
    },
  },
});

export const { setCurrentTab, setUserData } = userSlice.actions;
export const userState = (state) => state.user;
export default userSlice.reducer;

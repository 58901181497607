import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
const initialState = {
  loading:true,
  dashboardTab: "CSV Upload",
  sosCollection: "",
};

export const getSos = createAsyncThunk(
  "dashboard/getSos",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const getDoc = httpsCallable(functions, "getSos");
      const response = await getDoc({});
      console.log(response);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardTab: (state, action) => {
      state.dashboardTab = action.payload.tab;
    },
    setSosCollection: (state, action) => {
      state.sosCollection = action.payload.tab;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSos.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSos.fulfilled, (state, action) => {
        state.loading = false;
        state.sosCollection= action.payload.data
      })
      .addCase(getSos.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setDashboardTab,setSosCollection } = dashboardSlice.actions;
export const dashboardState = (state) => state.dashboard;
export default dashboardSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db_firestore } from "../firebase/config";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";

const initialState = {
  ticketData: "",
};

export const flightCSVAsync = createAsyncThunk(
  "ticket/flightCSV",
  async ({ parsedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { ticketData } = getState().ticket;
 
      const responses = [];

      for (const ticket of parsedData) {
        const response2 = await addDoc(
          collection(db_firestore, "flight","collection", ticket.PNR),
          ticket
        );
        responses.push(response2);
      }

      return responses;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTicketData: (state, action) => {
      state.ticketData = action.payload.value;
    },
  },
});

export const { setTicketData } = ticketSlice.actions;
export const ticketState = (state) => state.ticket;
export default ticketSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth, db_firestore } from "../firebase/config";
import { doc, getDocs, collection, addDoc, query } from "firebase/firestore";
const initialState = {
  currentIndex: null,
  BatchCollection:[]
};

export const getFeedsAsync = createAsyncThunk(
  "liveFeed/getFeeds",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const arr = ["Batch1", "Batch2", "Batch3"];
      let dataCollection = [];
      for (const item of arr) {
        const querySnapshot = await getDocs(
          collection(db_firestore, item, "collection", "liveFeed")
        );
        let response = [];

        querySnapshot.forEach((doc) => {
          response.push(doc.data());
        });
        dataCollection.push(response);
      }
      return dataCollection;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const liveFeedSlice = createSlice({
  name: "liveFeed",
  initialState,
  reducers: {
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload.index;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedsAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFeedsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.BatchCollection= action.payload
      })
      .addCase(getFeedsAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setCurrentIndex } = liveFeedSlice.actions;
export const liveFeedState = (state) => state.liveFeed;
export default liveFeedSlice.reducer;

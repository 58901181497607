import React, { useEffect } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "../container/Sidebar";
import Header from "../container/Header";
import customTheme from "../../utils/customTheme";
import UserForm from "../user/App";
import { useDispatch, useSelector } from "react-redux";
import { dashboardState, getSos } from "../../reducers/tabSlice";
import { Button, Container, Typography } from "@mui/joy";
import PushNotification from "../PushNotification/PushNotification";
import Polls from "../Polls/App";
import SOS from "../SOS/SOS";
import RolesPermission from "../LiveFeed/LiveFeed";
import TicketDetails from "../Ticket/App";
import { authState } from "../../reducers/authSlice";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Footer from "../container/Footer";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dashboardTab } = useSelector(dashboardState);
  const { isLogin } = useSelector(authState);
  useEffect(() => {
    dispatch(getSos({}));
  }, []);
  return (
    <CssVarsProvider disableTransitionOnChange theme={customTheme}>
      <GlobalStyles
        styles={{
          "[data-feather], .feather": {
            color: "var(--Icon-color)",
            margin: "var(--Icon-margin)",
            fontSize: "var(--Icon-fontSize, 20px)",
            width: "1em",
            height: "1em",
          },
        }}
      />
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh", width: "100%" }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          sx={(theme) => ({
            "--main-paddingTop": {
              xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
              md: "32px",
            },
            px: {
              xs: 2,
              md: 3,
            },
            pt: "var(--main-paddingTop)",
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          })}
        >
          {/* {isLogin ? ( */}
          <Container
            maxWidth="lg"
            sx={{ my: 4, height: "100%", padding: { xs: 1, md: "auto" } }}
          >
            {(dashboardTab === "CSV Upload" && <UserForm />) ||
              (dashboardTab === "Push Notification" && <PushNotification />) ||
              (dashboardTab === "Live Feed" && <RolesPermission />) ||
              (dashboardTab === "Ticket Details" && <TicketDetails />) ||
              (dashboardTab === "SOS" && <SOS />) ||
              (dashboardTab === "FeedBack Form" && <Polls />)}
          </Container>
          <Footer />
          {/* ) : ( */}
          {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography>No data found, you've been logged out!!</Typography>
              <Button
                startDecorator={<ArrowBackIcon />}
                size="lg"
                sx={{ mt: 2 }}
                onClick={() => navigate("/")}
              >
                Sign In
              </Button>
            </Box> */}
          {/* )} */}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

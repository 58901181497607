import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import MenuIcon from "@mui/icons-material/Menu";
import ColorSchemeToggle from "./ColorSchemeToggle";
import { toggleSidebar } from "../../utils/utils";
import { Typography } from "@mui/material";

export default function Header() {
  return (
    <Sheet
      sx={{
        display: { xs: "flex", md: "none" },
        alignItems: "center",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "var(--Header-height)",
        zIndex: 9995,
        py: 1,
        px: 2,
        gap: 1,
        boxShadow: "sm",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Header-height": "52px",
            [theme.breakpoints.up("md")]: {
              "--Header-height": "0px",
            },
          },
        })}
      />
      <IconButton onClick={() => toggleSidebar()} color="neutral" size="sm">
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" sx={{ boxShadow: "none", mr: "auto" }}>
        YourTravelBuzz
      </Typography>
      <ColorSchemeToggle id={undefined} />
    </Sheet>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../reducers/tabSlice";
import userReducer from "../reducers/userSlice";
import pushNotificationReducer from "../reducers/pushNotificationSlice";
import liveFeedReducer from "../reducers/liveFeedSlice";
import authReducer from "../reducers/authSlice";
import ticketReducer from "../reducers/ticketSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    user: userReducer,
    pushNotification: pushNotificationReducer,
    liveFeed: liveFeedReducer,
    auth: authReducer,
    ticket: ticketReducer,
  },
});

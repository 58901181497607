import React, { useEffect, useState } from "react";
import Typography from "@mui/joy/Typography";
import { Box, Container, Divider, Input, Select, Option } from "@mui/joy";
// icons

import SearchIcon from "@mui/icons-material/Search";
import LiveFeedCard from "../container/LiveFeedCard";
import { useDispatch, useSelector } from "react-redux";
import { getFeedsAsync, liveFeedState } from "../../reducers/liveFeedSlice";

export default function LiveFeed() {
  const dispatch = useDispatch();
  const { BatchCollection } = useSelector(liveFeedState);
  const [batch, setBatch] = useState(0);
  const [hashCode, setHashCode] = useState("#Airportlook");
  useEffect(() => {
    dispatch(getFeedsAsync({}));
  }, []);
  return (
    <Container maxWidth="lg" sx={{ padding: { xs: 0, md: "auto" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "start", md: "space-between" },
          alignItems: { xs: "start", md: "end" },
          gap: { xs: 3, md: 0 },
        }}
      >
        <Typography level="h5" className="text-nowrap">
          Live Feed
        </Typography>
        <Box sx={{ width: { xs: "100%", md: "auto" } }}>
          <Select
            defaultValue="0"
            onChange={(e, newValue) => setBatch(newValue)}
          >
            <Option value="0">Batch 1</Option>
            <Option value="1">Batch 2</Option>
            <Option value="2">Batch 3</Option>
            <Option value="3">Batch 4</Option>
          </Select>
          <Select
            defaultValue="#Airportlook"
            onChange={(e, newValue) => setHashCode(newValue)}
          >
            <Option value="#Airportlook">#Airportlook</Option>
            <Option value="#Galalook">#Galalook</Option>
            <Option value="#Bestcouple">#Bestcouple</Option>
            <Option value="#Smartestkid">#Smartestkid</Option>
            <Option value="#Bestsmile">#Bestsmile</Option>
            <Option value="#Besttraditional">#Besttraditional</Option>
            <Option value="#Budapestsky">#Budapestsky</Option>
            <Option value="#Dabang">#Dabang</Option>
            <Option value="#Colourful">#Colourful</Option>
            <Option value="">No Hashtag</Option>
          </Select>
        </Box>
      </Box>
      <Divider className="my-2 my-md-4" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 3,
          pb: 5,
        }}
      >
        {BatchCollection[batch]
          ?.filter((item) => item.hash.includes(hashCode))
          .map((item, i) => (
            <Box
              key={i}
              sx={{
                width: {
                  xs: "100%",
                  md: "calc(100% / 2.5)",
                  lg: "calc(100% / 3.5)",
                },
              }}
            >
              <LiveFeedCard index={i} item={item} />
            </Box>
          ))}
      </Box>
    </Container>
  );
}

import { Container, Typography } from "@mui/joy";
import React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { dashboardState } from "../../reducers/tabSlice";
import { useSelector } from "react-redux";
export default function SOS() {
  const { sosCollection } = useSelector(dashboardState);
  // Helper function to convert timestamp to human-readable time
  const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000);
    const timeString = date.toLocaleTimeString();
    return timeString;
  };

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp._seconds * 1000);
    const dateString = date.toLocaleDateString();
    return dateString;
  };

  return (
    <Container>
      <Typography level="h5" mb={3}>
        SOS
      </Typography>
      <Sheet
        sx={{
          height: { xs: 400, md: 400 },
          width: "100%",
          overflow: "auto",
        }}
      >
        {/* <Box sx={{ overflowX: { xs: "auto", md: "none" } }}> */}
        <Table
          aria-label="table with sticky header"
          stickyHeader
          stickyFooter
          stripe="odd"
          hoverRow
          style={{ minWidth: 700 }}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Name</th>
              <th>Phone </th>
              <th>Batch</th>
            </tr>
          </thead>
          <tbody>
            {sosCollection.map(({ time, user }, i) => (
              <tr key={i}>
                <td>{convertTimestampToDate(time)}</td>
                <td>{convertTimestampToTime(time)}</td>
                <td>{user["Full name"]}</td>
                <td style={{ wordWrap: "break-word" }}>
                  {user["Mobile Number"]}
                </td>
                <td>{user["Batch"]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </Container>
  );
}

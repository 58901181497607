import { Box, Typography } from "@mui/joy";
import React from "react";

export default function Footer() {
  return (
    <Box className=" text-center py-2">
      <Typography level="body3">
        Copyright @ {new Date().getFullYear()} | Powered by Bizconnect
      </Typography>
    </Box>
  );
}

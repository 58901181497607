import React from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { useSelector } from "react-redux";
import { userState } from "../../reducers/userSlice";
import { Typography } from "@mui/joy";

export default function UserList() {
  const { userData } = useSelector(userState);
  return (
    <div>
      {userData ? (
        <Sheet
          sx={{
            height: { xs: 400, md: 400 },
            width: "100%",
            overflow: "auto",
          }}
        >
          {/* <Box sx={{ overflowX: { xs: "auto", md: "none" } }}> */}
          <Table
            aria-label="table with sticky header"
            stickyHeader
            stickyFooter
            stripe="odd"
            hoverRow
            style={{ minWidth: 700 }}
          >
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Name</th>
                <th>Email </th>
                <th>Mobile </th>
                <th>Batch</th>
                <th>PNR</th>
                <th>Retailer Firm</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((row, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{row["Full name"]}</td>
                  <td style={{ wordWrap: "break-word" }}>{row["Email"]}</td>
                  <td>{row["Mobile Number"]}</td>
                  <td>{row["Batch"]}</td>
                  <td>{row["PNR"]}</td>
                  <td>{row["Retailer Firm"]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
      ) : (
        <Typography level="h6">Please upload a file</Typography>
      )}
    </div>
  );
}
